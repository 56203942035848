<template lang="">
  <div>
    <b-row>
      <b-col cols="6">
        <b-select
          v-model="answer.kind"
          @change="handleChange"
          :disabled="Object.keys(answer.answers).length > 0"
        >
          <b-form-select-option :value="null"
            >Selecciona un Evalua 4</b-form-select-option
          >
          <b-form-select-option
            v-for="(test, index) in tests"
            :key="index"
            :value="index"
            >Evalua 4 {{ index }}</b-form-select-option
          >
        </b-select>
      </b-col>
      <b-col>
        <b-button pill variant="primary" @click="clearAnswer(answer.kind)">
          Limpiar y cambiar el nivel de evalua
        </b-button>
      </b-col>
    </b-row>
    <GenericTest
      v-if="answer.kind != null"
      :test="tests[answer.kind]"
      :test_id="test_id"
      :answer="answer"
      :semester="semester"
      :previous_answer="previous_answer"
    ></GenericTest>
  </div>
</template>
<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
    oldEvaluation() {
      if (this.oldEvaluation >= 0) {
        this.answer.kind = this.oldEvaluation;
      }
    },
  },
  computed: {
    required: function () {
      return this.tests[this.answer.kind]
        .filter((elem) => elem.var != null)
        .map((elem) => elem.var);
    },
    oldEvaluation() {
      if (Object.keys(this.previous_answer).length) {
        let firstVal = parseInt(Object.keys(this.previous_answer)[0]);
        if (
          (firstVal >= 0 && firstVal < 12) ||
          (firstVal >= 176 && firstVal < 180)
        )
          return 0;
        else if (
          (firstVal >= 12 && firstVal < 27) ||
          (firstVal >= 180 && firstVal < 184)
        ) {
          return 1;
        } else if (
          (firstVal >= 27 && firstVal < 42) ||
          (firstVal >= 184 && firstVal < 188)
        ) {
          return 2;
        } else if (
          (firstVal >= 42 && firstVal < 57) ||
          (firstVal >= 188 && firstVal < 192)
        ) {
          return 3;
        } else if (
          (firstVal >= 57 && firstVal < 74) ||
          (firstVal >= 192 && firstVal < 196)
        ) {
          return 4;
        } else if (
          (firstVal >= 74 && firstVal < 91) ||
          (firstVal >= 196 && firstVal < 200)
        ) {
          return 5;
        } else if (
          (firstVal >= 91 && firstVal < 108) ||
          (firstVal >= 200 && firstVal < 204)
        ) {
          return 6;
        } else if (
          (firstVal >= 108 && firstVal < 126) ||
          (firstVal >= 204 && firstVal < 208)
        ) {
          return 7;
        } else if (
          (firstVal >= 126 && firstVal < 143) ||
          (firstVal >= 208 && firstVal < 212)
        ) {
          return 8;
        } else if (
          (firstVal >= 143 && firstVal < 160) ||
          (firstVal >= 212 && firstVal < 216)
        ) {
          return 9;
        } else if (
          (firstVal >= 160 && firstVal < 176) ||
          (firstVal >= 216 && firstVal < 220)
        ) {
          return 10;
        }
      }
      return -1;
    },
  },
  created() {
    if (this.oldEvaluation >= 0) {
      this.answer.kind = this.oldEvaluation;
    }
  },
  methods: {
    handleChange(value) {
      this.clearAnswer(value);
      // console.log(value);
    },
    clearAnswer(value) {
      this.answer = {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
        kind: value,
      };
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
        kind: null,
      },
      tests: [
        [
          {
            type: "h3",
            label: "Prueba de Seriaci\u00f3n (SE)",
          },
          {
            label: "Puntaje",
            var: "1",
            type: "number",
          },
          {
            type: "h3",
            label: "Organizacion perceptiva (OP)",
          },
          {
            label: "Puntaje",
            var: "2",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Clasificaci\u00f3n (CA)",
          },
          {
            label: "Puntaje",
            var: "0",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Memoria verbal (MV)",
          },
          {
            label: "Puntaje",
            var: "3",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Sonidos y letras (SL)",
          },
          {
            label: "Puntaje",
            var: "8",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprensi\u00f3n oral (CO)",
          },
          {
            label: "Puntaje",
            var: "4",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Recepcion auditiva y articulacion (RA)",
          },
          {
            label: "Puntaje",
            var: "5",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Habilidades Fonologicas (HF)",
          },
          {
            label: "Puntaje",
            var: "6",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Velocidad de Nombrado (VN)",
          },
          {
            label: "Puntaje",
            var: "7",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Copia de dibujos (CD)",
          },
          {
            label: "Puntaje",
            var: "9",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Grafomotricidad (GM)",
          },
          {
            label: "Puntaje",
            var: "10",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de N\u00fameros y Cantidad (NC)",
          },
          {
            label: "Puntaje",
            var: "11",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "176", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "177", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "178", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "179", type: "number" },
        ],
        [
          {
            type: "h3",
            label: "Prueba de Seriaci\u00f3n (SE)",
          },
          {
            label: "Puntaje",
            var: "13",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Organizaci\u00f3n perceptiva (OP)",
          },
          {
            label: "Puntaje",
            var: "15",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Clasificaci\u00f3n (CA)",
          },
          {
            label: "Puntaje",
            var: "14",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Memoria y Atenci\u00f3n (MA)",
          },
          {
            label: "Puntaje",
            var: "12",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprension lectora (CL)",
          },
          {
            label: "Puntaje",
            var: "17",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Exactitud lectora (EL)",
          },
          {
            label: "Puntaje",
            var: "16",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortograf\u00eda Visual (OR)",
          },
          {
            label: "Puntaje",
            var: "19",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Expresi\u00f3n escrita (GR)",
          },
          {
            label: "Puntaje",
            var: "20",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortografia fon\u00e9tica (OF)",
          },
          {
            label: "Puntaje",
            var: "18",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de C\u00e1lculo y numeraci\u00f3n (CN)",
          },
          {
            label: "Puntaje",
            var: "21",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Resoluci\u00f3n de problemas (RP)",
          },
          {
            label: "Puntaje",
            var: "22",
            type: "number",
          },
          {
            type: "h3",
            label: "Motivaci\u00f3n escolar",
          },
          {
            label: "Puntaje",
            var: "23",
            type: "number",
          },
          {
            type: "h3",
            label: "Autocontrol y autonomia",
          },
          {
            label: "Puntaje",
            var: "24",
            type: "number",
          },
          {
            type: "h3",
            label: "Conductas pro sociales",
          },
          {
            label: "Puntaje",
            var: "25",
            type: "number",
          },
          {
            type: "h3",
            label: "Autoconcepto y autoestima",
          },
          {
            label: "Puntaje",
            var: "26",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "180", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "181", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "182", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "183", type: "number" },
        ],
        [
          {
            type: "h3",
            label: "Prueba de Reflexividad (RE)",
          },
          {
            label: "Puntaje",
            var: "30",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Analog\u00edas (PA)",
          },
          {
            label: "Puntaje",
            var: "28",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Organizaci\u00f3n perceptiva (OP)",
          },
          {
            label: "Puntaje",
            var: "29",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Memoria y atenci\u00f3n (MA)",
          },
          {
            label: "Puntaje",
            var: "27",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprension lectora (CL)",
          },
          {
            label: "Puntaje",
            var: "31",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Exactitud lectora (EL)",
          },
          {
            label: "Puntaje",
            var: "32",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortografia visual (OR)",
          },
          {
            label: "Puntaje",
            var: "34",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Expresi\u00f3n escrita (GR)",
          },
          {
            label: "Puntaje",
            var: "35",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortografia fon\u00e9tica (OF)",
          },
          {
            label: "Puntaje",
            var: "33",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de C\u00e1lculo y numeraci\u00f3n (CN)",
          },
          {
            label: "Puntaje",
            var: "36",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Resoluci\u00f3n de problemas (RP)",
          },
          {
            label: "Puntaje",
            var: "37",
            type: "number",
          },
          {
            type: "h3",
            label: "Motivaci\u00f3n escolar",
          },
          {
            label: "Puntaje",
            var: "38",
            type: "number",
          },
          {
            type: "h3",
            label: "Autocontrol y autonomia",
          },
          {
            label: "Puntaje",
            var: "39",
            type: "number",
          },
          {
            type: "h3",
            label: "Conductas pro sociales",
          },
          {
            label: "Puntaje",
            var: "40",
            type: "number",
          },
          {
            type: "h3",
            label: "Autoconcepto y autoestima",
          },
          {
            label: "Puntaje",
            var: "41",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "184", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "185", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "186", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "187", type: "number" },
        ],
        [
          {
            type: "h3",
            label: "Prueba de Reflexividad (RE)",
          },
          {
            label: "Puntaje",
            var: "43",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Analog\u00edas (PA)",
          },
          {
            label: "Puntaje",
            var: "44",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Organizaci\u00f3n perceptiva (OP)",
          },
          {
            label: "Puntaje",
            var: "45",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Memoria y atenci\u00f3n (MA)",
          },
          {
            label: "Puntaje",
            var: "42",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprension lectora (CL)",
          },
          {
            label: "Puntaje",
            var: "46",
            type: "number",
          },
          {
            type: "h3",
            label: "Eficacia lectora (EF)",
          },
          {
            label: "Puntaje",
            var: "47",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortograf\u00eda Visual (OR)",
          },
          {
            label: "Puntaje",
            var: "50",
            type: "number",
          },
          {
            type: "h3",
            label: "Expresion escrita (GF)",
          },
          {
            label: "Puntaje",
            var: "49",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortografia fon\u00e9tica (OF)",
          },
          {
            label: "Puntaje",
            var: "48",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de C\u00e1lculo y numeraci\u00f3n (CN)",
          },
          {
            label: "Puntaje",
            var: "51",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Resoluci\u00f3n de problemas (RP)",
          },
          {
            label: "Puntaje",
            var: "52",
            type: "number",
          },
          {
            type: "h3",
            label: "Motivaci\u00f3n escolar",
          },
          {
            label: "Puntaje",
            var: "53",
            type: "number",
          },
          {
            type: "h3",
            label: "Autocontrol y autonomia",
          },
          {
            label: "Puntaje",
            var: "54",
            type: "number",
          },
          {
            type: "h3",
            label: "Conductas pro sociales",
          },
          {
            label: "Puntaje",
            var: "55",
            type: "number",
          },
          {
            type: "h3",
            label: "Autoconcepto y autoestima",
          },
          {
            label: "Puntaje",
            var: "56",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "188", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "189", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "190", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "191", type: "number" },
        ],
        [
          {
            type: "h3",
            label: "Prueba de Reflexividad (RE)",
          },
          {
            label: "Puntaje",
            var: "58",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Analog\u00edas (PA)",
          },
          {
            label: "Puntaje",
            var: "59",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Organizaci\u00f3n perceptiva (OP)",
          },
          {
            label: "Puntaje",
            var: "60",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Memoria y atenci\u00f3n (MA)",
          },
          {
            label: "Puntaje",
            var: "57",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprension lectora (CL)",
          },
          {
            label: "Puntaje",
            var: "63",
            type: "number",
          },
          {
            type: "h3",
            label: "Eficacia lectora (EF)",
          },
          {
            label: "Puntaje",
            var: "64",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (comprensi\u00f3n)",
          },
          {
            label: "Puntaje",
            var: "61",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (palabras x minuto) (PPM)",
          },
          {
            label: "Puntaje",
            var: "62",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortograf\u00eda Visual (OR)",
          },
          {
            label: "Puntaje",
            var: "65",
            type: "number",
          },
          {
            type: "h3",
            label: "Expresion escrita (GR)",
          },
          {
            label: "Puntaje",
            var: "67",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortografia fon\u00e9tica (OF)",
          },
          {
            label: "Puntaje",
            var: "66",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de C\u00e1lculo y numeraci\u00f3n (CN)",
          },
          {
            label: "Puntaje",
            var: "68",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Resoluci\u00f3n de problemas (RP)",
          },
          {
            label: "Puntaje",
            var: "69",
            type: "number",
          },
          {
            type: "h3",
            label: "Motivaci\u00f3n escolar",
          },
          {
            label: "Puntaje",
            var: "70",
            type: "number",
          },
          {
            type: "h3",
            label: "Autocontrol y autonomia",
          },
          {
            label: "Puntaje",
            var: "71",
            type: "number",
          },
          {
            type: "h3",
            label: "Conductas pro sociales",
          },
          {
            label: "Puntaje",
            var: "72",
            type: "number",
          },
          {
            type: "h3",
            label: "Autoconcepto y autoestima",
          },
          {
            label: "Puntaje",
            var: "73",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "192", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "193", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "194", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "195", type: "number" },
        ],
        [
          {
            type: "h3",
            label: "Prueba de Reflexividad (RE)",
          },
          {
            label: "Puntaje",
            var: "75",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Analog\u00edas (PA)",
          },
          {
            label: "Puntaje",
            var: "76",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Organizaci\u00f3n perceptiva (OP)",
          },
          {
            label: "Puntaje",
            var: "77",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Memoria y atenci\u00f3n (MA)",
          },
          {
            label: "Puntaje",
            var: "74",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprension lectora (CL)",
          },
          {
            label: "Puntaje",
            var: "78",
            type: "number",
          },
          {
            type: "h3",
            label: "Eficacia Lectora (EF)",
          },
          {
            label: "Puntaje",
            var: "79",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (nivel de comprensi\u00f3n)",
          },
          {
            label: "Puntaje",
            var: "80",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (palabras x minuto) (PPM)",
          },
          {
            label: "Puntaje",
            var: "81",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortograf\u00eda Visual (OR)",
          },
          {
            label: "Puntaje",
            var: "83",
            type: "number",
          },
          {
            type: "h3",
            label: "Grafia y expresion escrita (GR)",
          },
          {
            label: "Puntaje",
            var: "84",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortografia fon\u00e9tica (OF)",
          },
          {
            label: "Puntaje",
            var: "82",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de C\u00e1lculo y numeraci\u00f3n (CN)",
          },
          {
            label: "Puntaje",
            var: "85",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Resoluci\u00f3n de problemas (RP)",
          },
          {
            label: "Puntaje",
            var: "86",
            type: "number",
          },
          {
            type: "h3",
            label: "Motivaci\u00f3n escolar",
          },
          {
            label: "Puntaje",
            var: "87",
            type: "number",
          },
          {
            type: "h3",
            label: "Autocontrol y autonomia",
          },
          {
            label: "Puntaje",
            var: "88",
            type: "number",
          },
          {
            type: "h3",
            label: "Conductas pro sociales",
          },
          {
            label: "Puntaje",
            var: "89",
            type: "number",
          },
          {
            type: "h3",
            label: "Autoconcepto y autoestima",
          },
          {
            label: "Puntaje",
            var: "90",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "196", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "197", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "198", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "199", type: "number" },
        ],
        [
          {
            type: "h3",
            label: "Prueba de Reflexividad (RE)",
          },
          {
            label: "Puntaje",
            var: "92",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Analog\u00edas (PA)",
          },
          {
            label: "Puntaje",
            var: "93",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Organizaci\u00f3n perceptiva (OP)",
          },
          {
            label: "Puntaje",
            var: "94",
            type: "number",
          },
          {
            type: "h3",
            label: "Memoria y atenci\u00f3n (MA)",
          },
          {
            label: "Puntaje",
            var: "91",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprension lectora (CL)",
          },
          {
            label: "Puntaje",
            var: "96",
            type: "number",
          },
          {
            type: "h3",
            label: "Eficacia lectora (EF)",
          },
          {
            label: "Puntaje",
            var: "95",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (Comprensi\u00f3n)",
          },
          {
            label: "Puntaje",
            var: "97",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (Palabras x minuto) (PPM)",
          },
          {
            label: "Puntaje",
            var: "98",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortograf\u00eda Visual (OR)",
          },
          {
            label: "Puntaje",
            var: "100",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Expresi\u00f3n escrita (GR)",
          },
          {
            label: "Puntaje",
            var: "101",
            type: "number",
          },
          {
            type: "h3",
            label: "Ortografia fon\u00e9tica (OF)",
          },
          {
            label: "Puntaje",
            var: "99",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de C\u00e1lculo y numeraci\u00f3n (CN)",
          },
          {
            label: "Puntaje",
            var: "102",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Resoluci\u00f3n de problemas (RP)",
          },
          {
            label: "Puntaje",
            var: "103",
            type: "number",
          },
          {
            type: "h3",
            label: "Motivaci\u00f3n escolar",
          },
          {
            label: "Puntaje",
            var: "104",
            type: "number",
          },
          {
            type: "h3",
            label: "Autocontrol y autonomia",
          },
          {
            label: "Puntaje",
            var: "105",
            type: "number",
          },
          {
            type: "h3",
            label: "Conductas pro sociales",
          },
          {
            label: "Puntaje",
            var: "106",
            type: "number",
          },
          {
            type: "h3",
            label: "Autoconcepto y autoestima",
          },
          {
            label: "Puntaje",
            var: "107",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "200", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "201", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "202", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "203", type: "number" },
        ],
        [
          {
            type: "h3",
            label: "Razonamiento inductivo (RI)",
          },
          {
            label: "Puntaje",
            var: "110",
            type: "number",
          },
          {
            type: "h3",
            label: "Razonamiento espacial (RS)",
          },
          {
            label: "Puntaje",
            var: "111",
            type: "number",
          },
          {
            type: "h3",
            label: "Razonamiento deductivo (RD)",
          },
          {
            label: "Puntaje",
            var: "109",
            type: "number",
          },
          {
            type: "h3",
            label: "Atencion concentracion (AT)",
          },
          {
            label: "Puntaje",
            var: "108",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprension lectora (CL)",
          },
          {
            label: "Puntaje",
            var: "113",
            type: "number",
          },
          {
            type: "h3",
            label: "Eficacia lectora (EF)",
          },
          {
            label: "Puntaje",
            var: "112",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (comprensi\u00f3n)",
          },
          {
            label: "Puntaje",
            var: "114",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (Palabras x minuto) (PPM)",
          },
          {
            label: "Puntaje",
            var: "115",
            type: "number",
          },
          {
            type: "h3",
            label: "Expresion escrita (EE)",
          },
          {
            label: "Puntaje",
            var: "118",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortografia fon\u00e9tica",
          },
          {
            label: "Puntaje",
            var: "116",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortograf\u00eda Visual",
          },
          {
            label: "Puntaje",
            var: "117",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de C\u00e1lculo y numeraci\u00f3n (CN)",
          },
          {
            label: "Puntaje",
            var: "119",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Resoluci\u00f3n de problemas (RP)",
          },
          {
            label: "Puntaje",
            var: "120",
            type: "number",
          },
          {
            type: "h3",
            label: "Estrategias de trabajo y estudio",
          },
          {
            label: "Puntaje",
            var: "121",
            type: "number",
          },
          {
            type: "h3",
            label: "Motivaci\u00f3n escolar",
          },
          {
            label: "Puntaje",
            var: "122",
            type: "number",
          },
          {
            type: "h3",
            label: "Autocontrol y autonomia",
          },
          {
            label: "Puntaje",
            var: "123",
            type: "number",
          },
          {
            type: "h3",
            label: "Conductas pro sociales",
          },
          {
            label: "Puntaje",
            var: "124",
            type: "number",
          },
          {
            type: "h3",
            label: "Autoconcepto y autoestima",
          },
          {
            label: "Puntaje",
            var: "125",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "204", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "205", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "206", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "207", type: "number" },
        ],
        [
          {
            type: "h3",
            label: "Razonamiento inductivo (RI)",
          },
          {
            label: "Puntaje",
            var: "127",
            type: "number",
          },
          {
            type: "h3",
            label: "Razonamiento espacial (RS)",
          },
          {
            label: "Puntaje",
            var: "128",
            type: "number",
          },
          {
            type: "h3",
            label: "Razonamiento deductivo (RD)",
          },
          {
            label: "Puntaje",
            var: "129",
            type: "number",
          },
          {
            type: "h3",
            label: "Atencion concentracion (AT)",
          },
          {
            label: "Puntaje",
            var: "126",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprension lectora (CL)",
          },
          {
            label: "Puntaje",
            var: "131",
            type: "number",
          },
          {
            type: "h3",
            label: "Eficacia lectora (EF)",
          },
          {
            label: "Puntaje",
            var: "130",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (comprensi\u00f3n)",
          },
          {
            label: "Puntaje",
            var: "132",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (palabras x minuto) (PPM)",
          },
          {
            label: "Puntaje",
            var: "133",
            type: "number",
          },
          {
            type: "h3",
            label: "Expresion escrita (EE)",
          },
          {
            label: "Puntaje",
            var: "134",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortograf\u00eda Visual (OR)",
          },
          {
            label: "Puntaje",
            var: "135",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de C\u00e1lculo y numeraci\u00f3n (CN)",
          },
          {
            label: "Puntaje",
            var: "136",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Resoluci\u00f3n de problemas (RP)",
          },
          {
            label: "Puntaje",
            var: "137",
            type: "number",
          },
          {
            type: "h3",
            label: "Estrategias de trabajo y estudio",
          },
          {
            label: "Puntaje",
            var: "138",
            type: "number",
          },
          {
            type: "h3",
            label: "Adaptación Personal",
          },
          {
            label: "Puntaje",
            var: "139",
            type: "number",
          },
          {
            type: "h3",
            label: "Adaptación Familiar",
          },
          {
            label: "Puntaje",
            var: "140",
            type: "number",
          },
          {
            type: "h3",
            label: "Adaptación Escolar",
          },
          {
            label: "Puntaje",
            var: "141",
            type: "number",
          },
          {
            type: "h3",
            label: "Habilidades Sociales",
          },
          {
            label: "Puntaje",
            var: "142",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "208", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "209", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "210", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "211", type: "number" },
        ],
        [
          {
            type: "h3",
            label: "Razonamiento inductivo (RI)",
          },
          {
            label: "Puntaje",
            var: "144",
            type: "number",
          },
          {
            type: "h3",
            label: "Razonamiento espacial (RS)",
          },
          {
            label: "Puntaje",
            var: "145",
            type: "number",
          },
          {
            type: "h3",
            label: "Razonamiento deductivo (RD)",
          },
          {
            label: "Puntaje",
            var: "146",
            type: "number",
          },
          {
            type: "h3",
            label: "Atencion concentracion (AT)",
          },
          {
            label: "Puntaje",
            var: "143",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprension lectora (CL)",
          },
          {
            label: "Puntaje",
            var: "147",
            type: "number",
          },
          {
            type: "h3",
            label: "Eficacia lectora (EF)",
          },
          {
            label: "Puntaje",
            var: "148",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (nivel de comprensi\u00f3n)",
          },
          {
            label: "Puntaje",
            var: "149",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (palabras x minuto) (PPM)",
          },
          {
            label: "Puntaje",
            var: "150",
            type: "number",
          },
          {
            type: "h3",
            label: "Expresion escrita (EE)",
          },
          {
            label: "Puntaje",
            var: "152",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortograf\u00eda Visual (OR)",
          },
          {
            label: "Puntaje",
            var: "151",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de C\u00e1lculo y numeraci\u00f3n (CN)",
          },
          {
            label: "Puntaje",
            var: "153",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Resoluci\u00f3n de problemas (RP)",
          },
          {
            label: "Puntaje",
            var: "154",
            type: "number",
          },
          {
            type: "h3",
            label: "Estrategias de trabajo y estudio",
          },
          {
            label: "Puntaje",
            var: "155",
            type: "number",
          },
          {
            type: "h3",
            label: "Adaptación Personal",
          },
          {
            label: "Puntaje",
            var: "156",
            type: "number",
          },
          {
            type: "h3",
            label: "Adaptación Familiar",
          },
          {
            label: "Puntaje",
            var: "157",
            type: "number",
          },
          {
            type: "h3",
            label: "Adaptación Escolar",
          },
          {
            label: "Puntaje",
            var: "158",
            type: "number",
          },
          {
            type: "h3",
            label: "Habilidades Sociales",
          },
          {
            label: "Puntaje",
            var: "159",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "212", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "213", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "214", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "215", type: "number" },
        ],
        [
          {
            type: "h3",
            label: "Razonamiento inductivo (RI)",
          },
          {
            label: "Puntaje",
            var: "161",
            type: "number",
          },
          {
            type: "h3",
            label: "Razonamiento espacial (RS)",
          },
          {
            label: "Puntaje",
            var: "162",
            type: "number",
          },
          {
            type: "h3",
            label: "Razonamiento deductivo (RD)",
          },
          {
            label: "Puntaje",
            var: "163",
            type: "number",
          },
          {
            type: "h3",
            label: "Atencion concentracion (AT)",
          },
          {
            label: "Puntaje",
            var: "160",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (nivel de comprensi\u00f3n)",
          },
          {
            label: "Puntaje",
            var: "164",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Comprension lectora (CL)",
          },
          {
            label: "Puntaje",
            var: "166",
            type: "number",
          },
          {
            type: "h3",
            label: "Velocidad lectora (palabras x minuto) (PPM)",
          },
          {
            label: "Puntaje",
            var: "165",
            type: "number",
          },
          {
            type: "h3",
            label: "Expresion escrita (EE)",
          },
          {
            label: "Puntaje",
            var: "168",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Ortograf\u00eda Visual (OR)",
          },
          {
            label: "Puntaje",
            var: "167",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de C\u00e1lculo y numeraci\u00f3n (CN)",
          },
          {
            label: "Puntaje",
            var: "169",
            type: "number",
          },
          {
            type: "h3",
            label: "Prueba de Resoluci\u00f3n de problemas (RP)",
          },
          {
            label: "Puntaje",
            var: "170",
            type: "number",
          },
          {
            type: "h3",
            label: "Estrategias de trabajo y estudio",
          },
          {
            label: "Puntaje",
            var: "171",
            type: "number",
          },
          {
            type: "h3",
            label: "Adaptación Personal",
          },
          {
            label: "Puntaje",
            var: "172",
            type: "number",
          },
          {
            type: "h3",
            label: "Adaptación Familiar",
          },
          {
            label: "Puntaje",
            var: "173",
            type: "number",
          },
          {
            type: "h3",
            label: "Adaptación Escolar",
          },
          {
            label: "Puntaje",
            var: "174",
            type: "number",
          },
          {
            type: "h3",
            label: "Habilidades Sociales",
          },
          {
            label: "Puntaje",
            var: "175",
            type: "number",
          },
          { type: "h3", label: "Prueba de Índice cognitivo (IGC)" },
          { label: "Puntaje", var: "216", type: "number" },
          { type: "h3", label: "Prueba de Índice lectura (IGL)" },
          { label: "Puntaje", var: "217", type: "number" },
          { type: "h3", label: "Prueba de Índice escritura (IGE)" },
          { label: "Puntaje", var: "218", type: "number" },
          { type: "h3", label: "Prueba de Índice matemáticas (IGM)" },
          { label: "Puntaje", var: "219", type: "number" },
        ],
      ],
    };
  },
};
</script>

<style lang=""></style>
:required_fields="required"
