<template lang="">
  <div>
    <GenericTest
      :test="test"
      :test_id="test_id"
      :semester="semester"
      :required_fields="required"
      :answer="answer"
      :previous_answer="previous_answer"
    ></GenericTest>
  </div>
</template>
<script>
import GenericTest from "./GenericTest";
export default {
  components: { GenericTest },
  props: ["test_id", "student_id", "previous_answer", "semester"],
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
  },
  data() {
    return {
      answer: {
        test: this.test_id,
        answers: {},
        student_id: this.student_id,
      },
      test: [
        { type: "h3", label: "I NIVEL SEMANTICO" },
        {
          var: "evocacion_categorial",
          label: "1) Evocación categorial:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "relacion_terminos",
          label: "2) Relación de Términos por el Uso:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "definiciones",
          label: "3) Definiciones:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "semejanzas_verbales",
          label: "4) Semejanzas Verbales:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "asociacion_auditiva",
          label: "5) Asociación Auditiva:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "sinonimia_antonimia",
          label: "6) Sinonimia / Antonimia:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "comprension_lenguaje",
          label: "7) Comprensión del Lenguaje Figurado:",
          type: "number",
          min: "-50",
          required: true,
        },
        { type: "h3", label: "II NIVEL MORFOSINTACTICO" },
        { type: "h4", label: "1) Construcción de Oraciones" },
        {
          var: "una_palabra",
          label: "A) A partir de una palabra:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "dos_palabras",
          label: "B) A partir de dos palabras:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "completacion_oraciones",
          label: "2) Completación de Oraciones a través de un Nexo:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "narraciones",
          label: "3) Narraciones:",
          type: "number",
          min: "-50",
          required: true,
        },
        { type: "h4", label: "4) Descripciones" },
        {
          var: "primera_descripcion",
          label: "A) Primera descripción:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "segunda_descripcion",
          label: "B) Segunda descripción:",
          type: "number",
          min: "-50",
          required: true,
        },
        {
          var: "argumentaciones",
          label: "5) Argumentaciones:",
          type: "number",
          min: "-50",
          required: true,
        },
      ],
    };
  },
  computed: {
    required: function () {
      return this.test
        .filter((elem) => elem.required == true)
        .map((elem) => elem.var);
    },
  },
};
</script>
<style lang=""></style>
